.flag-select {
    position: relative;
    display: inline-block;
    vertical-align: inherit;
    padding-bottom: 5px;
    color: #4d4d4d;
    text-align: left;
    img {
        width: 1.3em;
        height: 1.3em;
        position: relative;
        top: 0.3em;
    }
    .selected--flag--option {
        cursor: pointer;
        padding: 0 8px;
        &:before {
            content: ' ';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 999;
        }
        &:focus {
            outline: none;
        }
        .country-label {
            padding-left: 9px;
        }
    }
    .country-flag {
        cursor: pointer;
        .country-label {
            font-size: 1em;
            position: relative;
        }
    }
    .flag-options {
        position: absolute;
        z-index: 999999;
        border: 1px solid #bdbbbb;
        border-radius: 3px;
        background: #ffffff;
        margin-top: 8px;
        padding: 8px 0;
        max-height: 160px;
        overflow: auto;
        &.to--left {
            right: 10px;
        }
    }
    .flag-option {
        cursor: pointer;
        padding: 0px 8px 3px 8px;
        margin: 4px 0;
        white-space: nowrap;
        &.has-label {
            padding: 0px 20px 3px 8px;
        }
        &:hover {
            background: #eaeaea;
        }
        &:focus {
            outline: none;
            background: #eaeaea;            
        }
        .country-label {
            padding: 0 9px;
        }
    }
    .filterBox{
        width: 100%;
        input {
            width: 90%;
            margin: 0 4%;

            &:focus {
                outline: none
            }
        }
    }
    .arrow-down {
        color: #4d4d4d;
        padding: 2px;
    }
    .hidden {
        display: none;
    }
    .no--focus {
        pointer-events: none;
    }
}
